@charset "utf-8";

// width
$width-page-pc: 1232px;
$width-sub-window: 500px;
$width-main: 940px;
$width-sub: 220px;
$width-contents-narrow: 800px;
$width-sidemenu: 220px;
$width-side-sp: 16px;
$height-header-sp: 96px;

// font
// $font-ja: 'Noto Sans JP','Yu Gothic Medium', '游ゴシック Medium', YuGothic, '游ゴシック体', "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,"メイリオ",Osaka,"MS PGothic",arial,helvetica,sans-serif;
$font-ja: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", MyYuGothicM, MyYuGothicM, YuGothic, "Yu Gothic", -apple-system, BlinkMacSystemFont, Meiryo, sans-serif;
$font-en: TradeGothic-bold, "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic ProN", "MyYuGothicM", MyYuGothicM, YuGothic, 'Yu Gothic', -apple-system, BlinkMacSystemFont, Meiryo, sans-serif;
$font-tradeGothic: TradeGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "MyYuGothicM", MyYuGothicM, YuGothic, 'Yu Gothic', -apple-system, BlinkMacSystemFont, Meiryo, sans-serif;
$font-tradeGothic-bold: TradeGothic-bold, "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic ProN", "MyYuGothicM", MyYuGothicM, YuGothic, 'Yu Gothic', -apple-system, BlinkMacSystemFont, Meiryo, sans-serif;
$font-archivo-expanded: 'Archivo', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "MyYuGothicM", MyYuGothicM, YuGothic, 'Yu Gothic', -apple-system, BlinkMacSystemFont, Meiryo, sans-serif;

$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-ex-light: 200;

// color
$color-text: #000;
$color-text2: #888;
$color-text3: #333;
$color-brand: #ffe512;
$color-red: #e60012;
$color-form-background: #f4f9fa;
$color-form-border: #cdd1d2;
$color-link: #00C;
$color-blue: #00a0e9;
$color-yellow: #fff100;
$color-star: #FEC600;
$color-popup: #fbf0f2;
$color-line: #06c755;

$color-background-gray: #efefef;

$color-btn-main: #000;
$color-btn-sub: #666;
$color-btn-cart: #FF9D2B;
$color-btn-cancel: #c0c0c0;
$color-btn-disabled: #EEE;
$color-text-disabled: #CCC;
$color-btn-favorite: #EEE;
$color-text-favorite: #888;
$color-icon-favorite-on: #e4007f;
$color-btn-estimate: #00a434;


