@charset "utf-8";

// #Media Query
//
// 使用例
// .foo {
//   color: blue;
//   @include mq() { // 引数を省略（初期値はmdの768px）
//     color: yellow;
//   }
//   @include mq(lg) { // 引数を個別に指定
//     color: red;
//   }
// }
// ==========================================================================
// $breakpoint-sm: $width-min-page !default;
// $breakpoint-md: $width-middle-page !default;
// $breakpoint-lg: $width-page-max !default;
$breakpoints: (
  'xsm': 'screen and (max-width: 320px)',
  'sm': 'screen and (max-width: 480px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 1024px)',
  'xlg': 'screen and (max-width: 1100px)',
  'xxlg': 'screen and (max-width: 1240px)',
) !default;
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// Presentation:
// ロールオーバーをまとめて出力
//
// Example:
// @include hover {
//   color: #999;
// }
// ==========================================================================
@mixin hover {
  &:hover, &:active, &:focus {
    @content;
  }
}


// Presentation:
// 矢印アイコン生成
//
// $icon-position  : 矢印の位置（top/right/bottom/left）
// $icon-size      : 矢印のサイズ
// $icon-color     : 矢印の色
// $icon-direction : 矢印の方向（top/right/bottom/left）
// $padding        : 親要素のpadding
// $pseudo-element : 疑似要素指定（初期値：before［省略可］）
//
// Example:
// @include icon-arrow('right', '7px', $color-black, 'bottom', '18px');
// ==========================================================================
@mixin icon-arrow($icon-position, $icon-size, $icon-color, $icon-direction, $padding, $pseudo-element: 'before') {
  @if $icon-position == 'top' {
    padding-top: #{$padding};
  } @else if $icon-position == 'right' {
    padding-right: #{$padding};
  } @else if $icon-position == 'bottom' {
    padding-bottom: #{$padding};
  } @else if $icon-position == 'left' {
    padding-left: #{$padding};
  }
  position: relative;
  display: inline-block;
  &:#{$pseudo-element} {
    content: '';
    width: #{$icon-size};
    height: #{$icon-size};
    border-bottom: 1px solid #{$icon-color};
    border-left: 1px solid #{$icon-color};
    margin: (-#{$icon-size}) 0 0;
    position: absolute;
    top: 50%;
    #{$icon-position}: 2px;
    display: block;
    @if $icon-direction == 'top' {
      transform: rotate(135deg);
    } @else if $icon-direction == 'right' {
      transform: rotate(-135deg);
    } @else if $icon-direction == 'bottom' {
      transform: rotate(-45deg);
    } @else if $icon-direction == 'left' {
      transform: rotate(45deg);
    }
  }
}


// #margin , padding
//
// Example:
// @include spacing(#{$i*5});
// ==========================================================================
@mixin spacing($size) {
  $type: 'u_p';
  $prop: 'padding';
  @for $i from 1 through 2 {
    @if $i > 1 {
      $type: 'u_m';
      $prop: 'margin';
    }
    .#{$type}t-#{$size} { #{$prop}-top:    #{$size}px !important; }
    .#{$type}r-#{$size} { #{$prop}-right:  #{$size}px !important; }
    .#{$type}b-#{$size} { #{$prop}-bottom: #{$size}px !important; }
    .#{$type}l-#{$size} { #{$prop}-left:   #{$size}px !important; }
  }
}


// Presentation:
// 行制限
//
// Example:
// @include line-clamp(3);
// ==========================================================================
@mixin line-clamp($lines) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}


// Presentation:
// 複数行制限（PC用）
//
// Example:
// @include line-clamp-pc(3, 5em);
// ==========================================================================
@mixin line-clamp-pc($lines, $height) {
  @if $lines == 1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  } @else {
    max-height: $height;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
  }
}


// #transform
// ==========================================================================
// Browser Prefixes
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  // -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  // -ms-transform-origin: $origin;
  transform-origin: $origin;
}


// #decimal point
// 小数の桁数と丸め方を制御する
//
// Example:
// @include round-decimal($number);
// @include ceil-decimal($number);
// @include floor-decimal($number);
// ==========================================================================
// Round (四捨五入)
@function round-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, 'round');
}

// Ceil (切り上げ)
@function ceil-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, 'ceil');
}

// Floor (切り捨て)
@function floor-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, 'floor');
}

@function to-fixed ($number, $digits: 0, $round: 'round') {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $round == 'round' {
    @return round($number * $n) / $n;
  } @else if $round == 'ceil' {
    @return ceil($number * $n) / $n;
  } @else if $round == 'floor' {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $round } is undefined keyword.';
    @return $number;
  }
}

// 別ウィンドウで開くリンクにアイコンをつける
@mixin new-window($color, $size) {
  position: relative;
  &::after {
    content: "";
    background-color: $color;
    mask: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 15.4 13.4"><path d="M11.4,13.4H1.4c-.77,0-1.4-.63-1.4-1.4V4c0-.77.63-1.4,1.4-1.4h2v.8H1.4c-.33,0-.6.27-.6.6v8c0,.33.27.6.6.6h10c.33,0,.6-.27.6-.6v-2h.8v2c0,.77-.63,1.4-1.4,1.4Z"/><path d="M14.4,9H5.4c-.55,0-1-.45-1-1V1c0-.55.45-1,1-1h9c.55,0,1,.45,1,1v7c0,.55-.45,1-1,1ZM5.4.8c-.11,0-.2.09-.2.2v7c0,.11.09.2.2.2h9c.11,0,.2-.09.2-.2V1c0-.11-.09-.2-.2-.2H5.4Z"/></svg>') no-repeat center center;
    width: $size;
    height: $size;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

@font-face {
  font-family: TradeGothic;
  unicode-range: U+0000-2BFF;
  src: url("../fonts/2CC084_0_0.eot"),
       url("../fonts/2CC084_0_0.eot?#iefix") format("embedded-opentype"),
       url("../fonts/2CC084_0_0.woff2") format("woff2"),
       url("../fonts/2CC084_0_0.woff") format("woff"),
       url("../fonts/2CC084_0_0.ttf") format("truetype"),
       url("../fonts/2CC084_0_0.svg#wf") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: TradeGothic-bold;
  unicode-range: U+0000-2BFF;
  src: url("../fonts/e4693908-7208-4b04-93c4-8d004c3b4e1f.eot") format("eot"),
       url("../fonts/e4693908-7208-4b04-93c4-8d004c3b4e1f.eot?iefix") format("embedded-opentype"),
       url("../fonts/e2fbc63c-e7b1-4828-b0b6-275131d16fd0.woff") format("woff"),
       url("../fonts/805d9356-a4a5-46df-8390-957c803e33bc.ttf") format("truetype"),
       url("../fonts/df0f9c50-3ce3-4b1f-8120-074ca66b83b1.svg#df0f9c50-3ce3-4b1f-8120-074ca66b83b1") format("svg");
}

@font-face {
  font-family: 'Archivo';
  font-weight: 300;
  src: url("../fonts/Archivo_Expanded-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'Archivo';
  font-weight: 400;
  src: url("../fonts/Archivo_Expanded-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Archivo';
  font-weight: 700;
  src: url("../fonts/Archivo_Expanded-ExtraBold.ttf") format("truetype");
}