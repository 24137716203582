@charset "UTF-8";

@import '../../../assets/css/global/variables';
@import '../../../assets/css/global/mixin';
@import '../../../assets/css/global/extend';

$font-family-a: $font-archivo-expanded;
$commonimgPath: "../images";
.dmj-top-mv{
  &-slider{
    &-button{
      &.swiper-button{
        display: block !important;
        @include mq(){
          display: none !important;
        }
      }
    }
    &-item{
      &-content{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        // padding: 0 20px;
        padding: 0;
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
          max-width: 100%;
          width: auto;
          aspect-ratio: unset;
        }
      }
      &-img{
        width: 100%;
        max-width: 100%;
        height: auto;
        img{
          aspect-ratio: 1600 / 1080;
          object-fit: cover;
          width: 100%;
          @include mq() {
            aspect-ratio: 750 / 750;
          }
        }
      }
      &-title{
        font-family: $font-archivo-expanded;
        font-size: 48px;
        letter-spacing: .02em;
        font-weight: 700;
        color: #fff;
        flex: 0 0 auto;
        img{
          width: 100%;
        }
        @include mq() {
          font-size: 28px;
          letter-spacing: .04em;
        }
      }
      &-btn {
        margin-top: 48px;
        .btn{
          display: flex;
          margin-left: auto;
          margin-right: auto;
          &.btn-size-m{
            @include mq() {
              width: 140px;
              height: 36px;
            }
          }
          &.bgYellow{
            background-color: $color-brand;
            color: #000;
            border: 1px solid $color-brand;
            &:hover{
              background-color: #000;
              color: $color-brand;
              border: 1px solid $color-brand;
            }
          }
          &.bgBlack{
            background-color: #000;
            color: $color-brand;
            border: 1px solid #000;
            &:hover{
              background-color: $color-brand;
              color: #000;
              border: 1px solid #000;
            }
          }
          &.bgBW{
            background-color: #000;
            color: #fff;
            border: 1px solid #000;
            &:hover{
              background-color: #fff;
              color: #000;
              border: 1px solid #000;
            }
          }
          &.bgWhite{
            background-color: #fff;
            color: #000;
            border: 1px solid #fff;
            &:hover{
              background-color: #000;
              color: #fff;
              border: 1px solid #000;
            }
          }
        }
      }
      &-link{
        &-sp{
          @include mq() {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 100;
          }
        }
      }
      &-deadlineTxt{
        font-size: 36px;
        margin-bottom: 40px;
        font-weight: 700;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq() {
          font-size: 8vw;
        }
      }
      &.showroomtyo{
        .dmj-top-mv-slider-item{
          &-title{
            font-size: 28px;
            font-family: $font-tradeGothic-bold;
            line-height: 1.2;
            img{
              width: 480px;
              margin: 0 auto 60px;
              @include mq(){
                width: 280px;
                margin: 0 auto 40px;
              }
            }
          }
        }
      }
      &.gakuwari{
        .dmj-top-mv-slider-item{
          &-title{
            min-width: 100%;
            @include mq(){
              display: none !important;
            }
          }
          &-btn{
            margin-top: -48px;
          }
        }
      }
      &.studentDiscount{
        .dmj-top-mv-slider-item{
          &-title{
            @include mq(){
              display: none !important;
            }
          }
        }
      }
      &.giftWrapping{
        .dmj-top-mv-slider-item{
          &-content{
            &-wrap{
              max-width: 1080px;
              // height: 510px;
              display: flex;
              @include mq() {
                flex-direction: column;
              }
              &-img{
                max-width: 50%;
                @include mq() {
                  max-width: 200px;
                }
              }
              &-txt{
                margin: 5vw 0 0 -60px;
                @include mq() {
                  margin: -18vw 0 0 0;
                }
              }
            }
          }
          &-title{
            font-size: 60px;
            font-family: $font-tradeGothic-bold;
            text-align: left;
            @include mq() {
              font-size: 48px;
              text-align: center;
            }
          }
          &-description{
            font-size: 24px;
            margin-top: 30px;
            color: #fff;
            @include mq() {
              font-size: 20px;
            }
          }
        }
      }
      &.yots{
        .dmj-top-mv-slider-item{
          &-title{
            font-size: 9.4vw;
          }
        }
      }
      &.whiteTxt{
        .dmj-top-mv-slider-item{
          &-title{
            color: #FFFFFF;
            text-shadow: 0 0 20px rgba(0, 0, 0, 1);
          }
        }
      }
      &.whiteStitch{
        .dmj-top-mv-slider-item{
          &-title{
            color: #FFFFFF;
            text-shadow: 0 0 20px rgba(0, 0, 0, 1);
          }
        }
      }
      &.finalSale{
        &-202502{
          .dmj-top-mv-slider-item{
            &-content{
              @include mq(){
                padding: 0 24px;
              }
            }
          }
        }
      }
      &.trashtop{
        .dmj-top-mv-slider-item{
          &-title{
            color: #F14961;
            text-shadow: 0 0 20px rgba(0, 0, 0, 1);
          }
        }
      }
      &.buzz{
        .dmj-top-mv-slider-item{
          &-content{
            @include mq(){
              top: auto;
              bottom: 0;
            }
          }
          &-btn{
            @include mq(){
              display: none !important;
            }
          }
          &-title{
            color: #FFFFFF;
            text-shadow: 0 0 20px rgba(0, 0, 0, 1);
          }
        }
      }
      &.bt21{
        .dmj-top-mv-slider-item{
          &-content{
            justify-content: flex-start;
            align-items: flex-start;
            top: 64%;
            left: 55%;
            @include mq(){
              top: 74%;
            }
          }
          &-title{
            text-align: left;
            color: #FFFFFF;
            text-shadow: 0 0 20px rgba(0, 0, 0, 1);
          }
          &-btn{
            @include mq(){
              margin-top: 0;
            }
          }
        }
      }
      &.specialSale{
        &-202502{
          .dmj-top-mv-slider-item{
            &-content{
              margin-top: 66px;
              @include mq(){
                display: none;
              }
            }
            &-title{
              font-size: 88px;
              color: $color-brand;
              text-shadow: 0 0 20px rgba(0, 0, 0, 1);
              margin: 10px 0 0;
            }
            &-description{
              font-family: $font-family-a;
              color: #FFFFFF;
              font-size: 78px;
              font-weight: 700;
              margin-bottom: 0;
            }
            &-deadlineTxt{
              margin-bottom: 0;
            }
            &-logo{
              position: absolute;
              top: auto;
              bottom: -150px;
              left: 50%;
              transform: translate(-50%, 0);
              width: 165px;
            }
            &-btn{
              margin-top: 40px;
            }
          }
        }
      }
      &.a65years{
        .dmj-top-mv-slider-item{
          &-content{
            justify-content: flex-start;
            align-items: flex-start;
            top: 64%;
            left: 55%;
            @include mq(){
              top: unset;
              bottom: 3%;
              width: 100%;
              max-width: 86.94%;
              left: 6.53%;
              transform: unset;
            }
          }
          &-title{
            color: #541A26;
            text-align: justify;
            text-align-last: justify;
            @include mq(){
              width: 100%;
              margin-inline: auto;
              font-size: 7.48vw;
              line-height: 1;
              margin-bottom: 5px;
            }
            span{
              span{
                display: block;
                &:not(:first-child){
                  @include mq(){
                  margin-top: -0.2em;
                  }
                }
              }
            }
          }
          &-btn{
            margin-top: 10px;
            @include mq(){
              margin-top: 0;
              width: 100%;
            }
            .btn{
              border: 1px solid #541A26;
              background-color: #541A26;
              color: #fff;
              @include mq(){
                width: 100%;
                margin-inline: auto;
              }
              &:hover{
                background-color: #fff;
                color: #541A26;
              }
            }
          }
        }
      }
      &.ss25_sandalCmp{
        .dmj-top-mv-slider-item-content{
          top: 70%;
          padding-inline: 5%;
          @include mq(){
            top: 50%;
          }
        }
        .dmj-top-mv-slider-item-title{
          text-align: left;
          margin-right: auto;
          text-shadow: 0 0 20px #000;
          @include mq(){
            text-align: center;
            margin-right: 0;
          }
          &>span{
            span{
              display: block;
              &:not(:first-child){
                margin-top: -0.3em;
              }
            }
          }
        }
        .dmj-top-mv-slider-item-btn{
          margin-right: auto;
          margin-top: 10px;
          @include mq(){
            margin-right: 0;
          }
        }
      }
      &.bratz{
        .dmj-top-mv-slider-item{
          &-content{
            justify-content: flex-start;
            align-items: flex-start;
            top: 48%;
            left: 55%;
            @include mq(){
              top: 78%;
            }
          }
          &-logo{
            filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
            @include mq(){
              max-width: 70%;
            }
          }
          &-btn{
            @include mq(){
              margin-top: 8px;
            }
          }
        }
      }
      &.lineFriends{
        &-20241025{
          .dmj-top-mv-slider-item{
            &-title{
              max-width: 38vw;
              @media screen and (max-width: 1280px){
                max-width: 480px;
              }
              @media screen and (max-width: 768px){
                max-width: 760px;
              }
              @include mq(){
                max-width: calc(100% - 40px);
                margin: 0 auto;
              }
            }
          }
        }
        &-20250404{
          .dmj-top-mv-slider-item{
            &-title{
              max-width: 860px;
              @media screen and (max-width: 768px){
                max-width: 720px;
              }
              @include mq(){
                max-width: calc(100% - 40px);
                margin: 0 auto;
              }
            }
          }
        }
      }
      &.ss25_palace{
        .dmj-top-mv-slider-item{
          &-content{
            justify-content: flex-start;
            align-items: flex-start;
            top: 78%;
            left: 55%;
            @include mq(){
              top: 52%;
              left: 20px;
              transform: none;
            }
          }
          &-title{
            @include mq(){
              max-width: 280px;
            }
          }
          &-link-sp{
            display: none !important;
          }
          &-btn{
            @include mq(){
              margin-top: 16px;
              display: block !important;
            }
          }
        }
      }
    }
  }
}

